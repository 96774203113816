import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import { BsArrowRight } from "react-icons/bs"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Link from "../components/Link"

const gradient = {
  light: {
    subtle:
      "linear-gradient(-270.61deg, rgba(255, 255, 255, 0.3) 0.41%, rgba(255, 255, 255, 0.139063) 26.66%, rgba(255, 254, 254, 0) 54.81%)",
    intense:
      "linear-gradient(-270.61deg, #FFFFFF 0.41%, rgba(255, 255, 255, 0.463542) 26.66%, rgba(255, 254, 254, 0) 54.81%)",
  },
  dark: {
    subtle:
      "linear-gradient(-269.71deg, rgba(0, 0, 0, 0.5) 2.83%, rgba(0, 0, 0, 0) 79.81%)",
    intense:
      "linear-gradient(-269.71deg, #000000 2.83%, rgba(0, 0, 0, 0) 79.81%)",
  },
}
const GradientCard = ({
  overline,
  header,
  primaryTextColor,
  secondaryTextColor,
  gradientVariant,
  children,
}) => {
  return (
    <Box
      borderRadius={3}
      sx={{
        boxShadow: "5px 5px 30px 10px rgba(35, 66, 101, 0.1)",
        ":hover": {
          boxShadow: "5px 5px 20px 10px rgba(110, 130, 154, 0.2)",
          ".intense-gradient": {
            opacity: 0,
          },
        },
        transition: "box-shadow 0.5s",
      }}
    >
      <Box position="relative">
        <Box
          position="absolute"
          zIndex={100}
          pl={4}
          pt={5.5}
          width={0.6}
          display="flex"
          flexDirection="column"
          gap={2.5}
        >
          <Typography
            variant="overline"
            fontSize="0.75rem !important"
            color={secondaryTextColor}
          >
            {overline}
          </Typography>
          <Typography variant="h5" fontWeight={400} color={primaryTextColor}>
            {header}
          </Typography>
          <BsArrowRight size={40} color={secondaryTextColor} />
        </Box>

        <Box
          className="intense-gradient"
          zIndex={10}
          width={1}
          height={1}
          position="absolute"
          sx={{
            borderRadius: 3,
            background: gradient[gradientVariant].intense,
            opacity: 1,
            transition: "opacity 0.5s",
          }}
        />
        <Box
          zIndex={10}
          width={1}
          height={1}
          position="absolute"
          sx={{
            borderRadius: 3,
            background: gradient[gradientVariant].subtle,
          }}
        />
        <Box
          height={{ xs: 250, sm: 300, lg: 250, xl: 300 }}
          sx={{
            "& *": { height: 1, borderRadius: 3 },
            img: {
              objectFit: "cover",
              width: "100%",
              height: "auto",
            },
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}
const EnlargePictureCard = ({
  overline,
  header,
  backgroundColor,
  primaryTextColor,
  secondaryTextColor,
  children,
}) => {
  return (
    <Box sx={{ display: { xs: "flex", lg: "block" }, height: 1 }}>
      <Box
        borderRadius={3}
        bgcolor={backgroundColor}
        sx={{
          boxShadow: "5px 5px 30px 10px rgba(35, 66, 101, 0.1)",
          display: { xs: "flex", lg: "block" },
          justifyContent: "space-between",
          flexDirection: "column",
          ".enlarge-photo": {
            transition: "width 0.5s",
          },
          ":hover": {
            boxShadow: "5px 5px 20px 10px rgba(110, 130, 154, 0.2)",
            ".enlarge-photo": {
              width: 1,
            },
          },
          transition: "box-shadow 0.5s",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          pl={4}
          pt={5.5}
          gap={2.5}
          width={0.6}
        >
          <Typography
            variant="overline"
            fontSize="0.75rem !important"
            color={secondaryTextColor}
          >
            {overline}
          </Typography>
          <Typography variant="h5" fontWeight={400} color={primaryTextColor}>
            {header}
          </Typography>
          <BsArrowRight size={40} color={secondaryTextColor} />
        </Box>
        <Box
          position="relative"
          display="flex"
          flexDirection="row-reverse"
          width={1}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

const ProductsPage = () => {
  const {
    strapiProductsPage: {
      overline,
      header,
      description,
      leftCard,
      rightCard,
      middleTopCard,
      lowerMiddleCard,
    },
  } = useStaticQuery(productsPageQuery)

  return (
    <Layout>
      <Box mt={{ xs: 1, md: 9 }} display="flex" flexDirection="column">
        <Typography
          variant="overline"
          color="primary"
          fontWeight={500}
          sx={{ mb: 0.5 }}
        >
          {overline}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          gap={{ xs: 2, lg: 14 }}
        >
          <Typography
            variant="h1"
            sx={{ flex: 1, maxWidth: { xs: 1, lg: 500 } }}
          >
            {header}
            <Typography
              variant="inherit"
              color="primary"
              sx={{ display: "inline-block" }}
            >
              .
            </Typography>
          </Typography>
          <Typography variant="body1" sx={{ flex: { xs: "1 100%", lg: 1 } }}>
            {description}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" flexWrap="wrap" gap={4}>
        <Link
          href={leftCard.uri}
          sx={{
            flex: { xs: "1 100%", md: "1 40%", lg: 1 },
            order: 1,
          }}
        >
          <EnlargePictureCard {...leftCard}>
            <Box
              mt={6.5}
              display="flex"
              flexDirection="row-reverse"
              width={1}
              sx={{ img: { borderRadius: 3 } }}
            >
              <GatsbyImage
                image={getImage(leftCard.backgroundImage.localFile)}
              />
            </Box>
            <Box
              className="enlarge-photo"
              position="absolute"
              left={0}
              bottom={0}
              width={0.875}
            >
              <GatsbyImage
                image={getImage(leftCard.enlargingImage.localFile)}
              />
            </Box>
          </EnlargePictureCard>
        </Link>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row", lg: "column" }}
          order={{ xs: 3, lg: 2 }}
          gap={4}
          sx={{ flex: { xs: "1 100%", md: "1 40%", lg: 1 } }}
        >
          <Link href={middleTopCard.link} sx={{ flex: 1 }}>
            <GradientCard {...middleTopCard}>
              <GatsbyImage image={getImage(middleTopCard.image.localFile)} />
            </GradientCard>
          </Link>

          <Link href={lowerMiddleCard.link} sx={{ flex: 1 }}>
            <GradientCard {...lowerMiddleCard}>
              <GatsbyImage image={getImage(lowerMiddleCard.image.localFile)} />
            </GradientCard>
          </Link>
        </Box>

        <Link
          href={rightCard.uri}
          sx={{
            display: { xs: "flex", lg: "block" },
            flex: { xs: "1 100%", md: "1 40%", lg: 1 },
            order: { xs: 2, lg: 3 },
          }}
        >
          <EnlargePictureCard {...rightCard}>
            <Box visibility="hidden" width={0.5}>
              <GatsbyImage
                image={getImage(rightCard.enlargingImage.localFile)}
              />
            </Box>
            <Box
              className="enlarge-photo"
              position="absolute"
              display="flex"
              flexDirection="row-reverse"
              right={0}
              bottom={0}
              width={{ xs: 1, xl: 0.925 }}
              sx={{ img: { borderRadius: 3 } }}
            >
              <GatsbyImage
                image={getImage(rightCard.enlargingImage.localFile)}
              />
            </Box>
          </EnlargePictureCard>
        </Link>
      </Box>
    </Layout>
  )
}

const productsPageQuery = graphql`
  query ProductsPageQuery {
    strapiProductsPage {
      overline
      header
      description

      leftCard {
        overline
        header
        backgroundColor
        primaryTextColor
        secondaryTextColor
        uri
        backgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        enlargingImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }

      middleTopCard {
        overline
        header
        gradientVariant
        primaryTextColor
        secondaryTextColor
        link
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }

      lowerMiddleCard {
        overline
        header
        gradientVariant
        primaryTextColor
        secondaryTextColor
        link
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }

      rightCard {
        overline
        header
        backgroundColor
        primaryTextColor
        secondaryTextColor
        uri
        backgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        enlargingImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default ProductsPage
